import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function Home({ data }) {
  const images = images =>
    images.map(({ node: { id, relativePath, name, childImageSharp } }) => (
      <div key={id}>
        <div>{name}</div> <Img fixed={childImageSharp.fixed} />
      </div>
    ))

  const albums = data.imagesGroupedByAlbum.group.map(
    ({ albumName, totalCount, edges }) => (
      <div key={albumName}>
        <h1>
          {albumName} Total:{totalCount}
        </h1>
        <div>{images(edges)}</div>
      </div>
    )
  )

  return (
    <div>
      <div>Albums</div>
      {albums}
    </div>
  )
}

const albumDefaultCover = graphql`
  query albumDefaultCover($albumName: String) {
    albumDefaultCover: file(
      relativeDirectory: { eq: $albumName }
      internal: { mediaType: { eq: "image/jpeg" } }
    ) {
      id
      base
      childImageSharp {
        fixed {
          src
        }
      }
    }
  }
`

export const pageQuery = graphql`
  {
    albums: allDirectory {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
      }
    }

    imagesGroupedByAlbum: allFile(
      filter: {
        sourceInstanceName: { eq: "albums" }
        internal: { mediaType: { eq: "image/jpeg" } }
      }
    ) {
      group(field: relativeDirectory) {
        albumName: fieldValue
        totalCount
        edges {
          node {
            id
            relativeDirectory
            relativePath
            extension
            size
            dir
            relativeDirectory
            base
            name
            childImageSharp {
              fixed(width: 100, height: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    configAlbums: allIndexJson {
      edges {
        node {
          albumName
          cover
          description
        }
      }
    }

    albumDefaultCover: file(
      relativeDirectory: { eq: "banners" }
      internal: { mediaType: { eq: "image/jpeg" } }
    ) {
      id
      base
      internal {
        type
        mediaType
      }
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query AllAlbumsWithImages {
//     allAlbum {
//       edges {
//         node {
//           id
//           name
//           children {
//             id
//             ... on File {
//               id
//               relativePath
//               childImageSharp {
//                 fixed(width: 100, height: 100) {
//                   ...GatsbyImageSharpFixed
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
